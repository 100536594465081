import React from "react"
import { Link } from "gatsby"
import styled, { withTheme } from 'styled-components';

import { rhythm, scale } from "../utils/typography"

class MenuList extends React.Component {
  render() {

    const Wrapper = styled.div`
      position: fixed;
      top: 0;
      right: 0;
      width: 100px;
      background-color: #FFD1AA;
      color: black;
      border-radius: 4px;
      margin: 15px;
      box-shadow: 0 5px 10px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
      &:hover {
        cursor: pointer;
      }
    `;

    const Menu = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      height: 100px;
      width: 100px;
      font-family: "Montserrat", sans-serif;
    `;

    const LinkList = styled.ul`
      list-style-type: none;
      font-family: "Montserrat", sans-serif;
    `

    return (
      <Wrapper id={this.props.id} onClick={this.props.toggleMenu}>
        <Menu>
          <b>Hide <br/>Menu</b>
        </Menu>
        <LinkList>
        	<li>About Me</li>
        	<li>Music</li>
        	<li>Projects</li>
          <li>Films</li>
        </LinkList>
      </Wrapper>
    )
  }
}

export default MenuList
