import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import BurgerMenu from "../../content/assets/burg-icon.png"

import { rhythm, scale } from "../utils/typography"

class MenuIcon extends React.Component {
  render() {

    const Wrapper = styled.div`
      position: fixed;
      top: 30px;
      right: 30px;
      height: 50px;
      width: 50px;
      color: black;
      border-radius: 50px;
      margin: 15px;
      z-index: 1;
      &:hover {
        cursor: pointer;
      }
    `;

    const Menu = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      height: 100px;
      width: 100px;
      z-index: 1;
      font-family: "Montserrat", sans-serif;
    `;

    return (
      <Wrapper id={this.props.id} onClick={this.props.toggleMenu}>
        <img 
          src={BurgerMenu}
        />
      </Wrapper>
      // <Wrapper id={this.props.id} onClick={this.props.toggleMenu}>
      //   <Menu>
      //     <b>Show <br/>Menu</b>
      //   </Menu>
      // </Wrapper>
    )
  }
}

export default MenuIcon
