import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { withTheme } from 'styled-components'
import "../styles.css"
import GoogleMapReact from 'google-map-react';

class Maps extends React.Component {
  state = {
    poop: true,
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    let film = document.getElementById("filmEmbed")
    console.log(film)
    film.setAttribute("src", selectedOption.value)
    console.log(film)
    console.log(selectedOption.value)
  };

  render() {
    const { data } = this.props
    const { selectedOption } = this.state;
    const siteTitle = data.site.siteMetadata.title
    const AboutWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100vw;
      height: 80vh;
    `;


    return (
      <Layout location={`/Van-Parks`} title={siteTitle}>
        <SEO title="Vancouver Ivanhoe Film Festival (VIFF)" />
        
        <div style={{ 
          overFlowY: 'scroll', 
          marginTop: '40px', 
          height: '94vh', 
          width: '100%',
          cursor: 'pointer'
           }}>
        
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBcuiHAFFYtMtktsFWyzn0BBh2XHDkhR_s' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
 
        >
        
        </GoogleMapReact>
        </div>

        
      </Layout>
    )
  }
}

export default Maps

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
